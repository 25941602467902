import { GameData } from "../model";

export const initGamesData = (): GameData[] => (
	[{
		id: 1,
		home: 'Buffalo Bills',
		away: 'New York Jets',
		votes: 0,
		date: '',
		time: '',
		highscore_game: "0",
		thriller_game: "0",
		defense_battle: "0",
	},
	{
		id: 2,
		home: 'Home Team 2',
		away: 'Away Team 2',
		votes: 0,
		date: '',
		time: '',
		highscore_game: "0",
		thriller_game: "0",
		defense_battle: "0",
	}]
	);