import Events  from "./events";
// import { StoredKeys } from "../../../constants";

const initialState = {
	arrData: {}	
};

const reducer = (state = initialState, action: { type: string; payload: { data: any; }; }) => {
	switch (action.type) {
		case Events.LOGIN + "_FULFILLED":
			// localStorage.setItem(StoredKeys.USER_DETAILS, window.btoa(JSON.stringify(action.payload.data.data)));
			return {
				...state,
				token: action.payload.data.data.token,
				team: action.payload.data.data.team,
				teamMemberCount: action.payload.data.data.teamMemberCount
			};
		case Events.LOGOUT: 
			// localStorage.removeItem(StoredKeys.USER_DETAILS);
			//refresh after clearing local storage to clear all data in redux
			setTimeout(() => {
				window.location.pathname = "/";
			}, 10);
			return {
				...state
			};
		case Events.SET_LOGIN: 
			return {
				...state,
				token: action.payload.data.token,
				team: action.payload.data.team
			};
		default:
			return state;
	}
};

export default reducer;