import React from 'react';
import { NavLink } from 'react-router-dom';

import { Menu } from 'semantic-ui-react';

const Nav = ({ onNavClick }) => {

  return (
    <Menu secondary>
      <Menu.Item
        as={NavLink}
        exact
        name="Home"
        // onClick={handleOnClick}
        to="/"
      />
      <Menu.Item
        as={NavLink}
        name="Imprint"
        // onClick={handleOnClick}
        to="/imprint"
      />
    </Menu>
  );
};

export default Nav;
