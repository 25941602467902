import axios from "axios";
import config from "../../../config";
import Events from "./events";

export const fetchAllGames = ()  => ({
	type: Events.FETCH_GAMES,
	payload: axios.get(`${config.API_URL}games`)
});

export const fetchAllGamesByWeek = (week?: number)  => ({
	type: Events.FETCH_GAMES_BY_WEEK,
	payload: axios.get(`${config.API_URL}games/${week}`)
});

export const voteGame = (gameId: number, voteCount: number) => ({
	type: Events.UPVOTE_GAME,
	payload: axios
				.post(`${config.API_URL}games/${gameId}/vote/${voteCount}`,
				{ 'userUuid' : localStorage.getItem('userUuid')})

});