import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Container, Header, Image, Modal } from 'semantic-ui-react'

import uuid from 'react-uuid';

import { fetchAllGamesByWeek, voteGame } from './games/redux/action';
import { useSelector } from "react-redux";
import { AppStore } from "../model/store.model";

import './games/containers/games.css'

import GameListItem from "../components/GameListItem";
import SubNav from "../components/SubNav";
import Footer from '../components/Footer';

import { GameDay2CalendarMapping } from "../constants";


const GamesListPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: AppStore) => state.games.gamesData);
  const [currentGameDay, setCurrentGameDay] = useState<number|undefined>(0);
  const [showMaxModal, setShowMaxModal] = useState<boolean>(false);
  const [showMaxDownModal, setShowMaxDownModal] = useState<boolean>(false);
  const [showGameNotStartedModal, setShowGameNotStartedModal] = useState<boolean>(false);

  const [userUuid, setUserUuid] = useState<string>("");

  const fetchData = useCallback(() => {
    let currentDate: any = new Date();
    const weekMappingObj = GameDay2CalendarMapping.find((obj) => {
      var gameDayStart: any = new Date(obj.startDate);
      var gameDayEnd: any = new Date(obj.endDate);

      return (gameDayStart - currentDate < 0 && gameDayEnd - currentDate > 0);
    });

    setCurrentGameDay(weekMappingObj?.gameday);

		dispatch(fetchAllGamesByWeek(weekMappingObj?.gameday));
  },[dispatch]);
  
  useEffect(() => {
		fetchData();   
	},[fetchData]);


  useEffect(() => {
    checkAndSetLocalUser();
  },[userUuid]);


  /**
   * checks if user uuid is set in localstorage. 
   * if not that means that user visits page for the first time. 
   * Or he uses a different browser/device to power vote ;)
   * @returns user uuid
   */
  const checkAndSetLocalUser = () => {
    var userUuid = localStorage.getItem('userUuid');

    if (userUuid) {
      setUserUuid(userUuid);
      return userUuid;
    }

    userUuid = uuid();
    setUserUuid(userUuid)
    localStorage.setItem('userUuid', userUuid);

    return userUuid;
  };
 
  /**
   * upvote a game
   * @param gameId Id of the game 
   * @returns 
   */
  const _upvoteGame = (gameId: number): void => {
    if (!checkIfVotingIsActive(gameId)){
      setShowGameNotStartedModal(true);
      return;
    }

    var voteCount = getVoteCount(gameId);
    if(voteCount >= 3){
      setShowMaxModal(true);
      return;
    }

    voteCount++;
    _saveVoteCountToLocalStorageAndSendToAPI(gameId, voteCount);
  };

  /**
   * downvote the game
   * @param gameId Id of the game
   * @returns 
   */
  const _downvoteGame = (gameId: number): void => {
    if (!checkIfVotingIsActive(gameId)){
      setShowGameNotStartedModal(true);
      return;
    }

    var voteCount = getVoteCount(gameId);
    if(voteCount === -3){
      setShowMaxDownModal(true);
      return;
    }

    voteCount--;
    _saveVoteCountToLocalStorageAndSendToAPI(gameId, voteCount);
  };

  /**
   * we check if voting for the current game is active.
   * thats true when game date - current time is lower than 0
   * @param gameId Id of the game
   */
  const checkIfVotingIsActive = (gameId: number): boolean => {
    let currentDate: any = new Date();
    var game = data.find((game) => {
      var gameStart: any = new Date(game.date + " " + game.time);
      
      return (game.id === gameId && gameStart - currentDate < 0);
    });

    return game !== undefined;
  };

  /**
   * save new vote count for game to localstorage and send it to api
   * 
   * @param gameId Id of the game
   * @param voteCount number of up or downvotes
   */
  const _saveVoteCountToLocalStorageAndSendToAPI = (gameId: number, voteCount: number): void => {
    localStorage.setItem('game' + gameId, voteCount + "");
    dispatch(voteGame(gameId, voteCount));
  };

  
  /**
   * check how often the user up- or downvoted the game
   * @param gameId 
   * @returns 
   */
  const getVoteCount = (gameId: number): number => {
    var gameCount = (localStorage.getItem('game' + gameId) === null) ? 0 : localStorage.getItem('game' + gameId);
    var iGameCount = 0; 

    if(gameCount !== null && typeof(gameCount) === "string"){
      iGameCount = parseInt(gameCount);
    }

    return iGameCount;
  };


  /**
   * load data for selected game day
   * @param gameday 
   */
  const onGameDayClicked = (gameday: string) : void => {
    setCurrentGameDay(parseInt(gameday));
    dispatch(fetchAllGamesByWeek(parseInt(gameday)));
  };  

  return (
    <React.Fragment>
      <Container>
        <Image src={require(`../images/games-voter-logo.jpg`)} className="logo" />
        <Header as='h2'>You don't have enough time to watch all games after game day<br />and you ask yourself which games you should watch?</Header>
        <Header as='h3'>Have a look how other users rated the games. Hopefully this helps you.</Header>
        
        <SubNav activeItem={currentGameDay} onGameDayClicked={onGameDayClicked} />

        {data && data.map((d: any) => {
          return (
            <div 
              className={`game_item`} 
              key={`street_work_card_${d.id}`}  
            >
              <GameListItem 
                game={d} 
                onUpvoteClicked={_upvoteGame}
                onDownvoteClicked={_downvoteGame}
                />
            
            </div>
          );
        })}

          <Modal
            // dimmer={dimmer}
            open={showMaxModal}
          >
            <Modal.Header>Holy Moly</Modal.Header>
            <Modal.Content>
              Looks like you really like this game ;) Thanks for voting. 3 times is max.
            </Modal.Content>
            <Modal.Actions>
              <Button positive onClick={() => setShowMaxModal(false)}>
                Okay okay
              </Button>
            </Modal.Actions>
          </Modal>


          <Modal
            open={showMaxDownModal}
          >
            <Modal.Header>Holy Moly</Modal.Header>
            <Modal.Content>
              Looks like this game really sucked ;) Thanks for voting. -3 times is max.
            </Modal.Content>
            <Modal.Actions>
              <Button positive onClick={() => setShowMaxDownModal(false)}>
                Okay okay
              </Button>
            </Modal.Actions>
          </Modal>


          <Modal
            open={showGameNotStartedModal}
          >
            <Modal.Header>Stay tuned</Modal.Header>
            <Modal.Content>
              I am also already excited about the game but voting after game is started helps others more ;)
            </Modal.Content>
            <Modal.Actions>
              <Button positive onClick={() => setShowGameNotStartedModal(false)}>
                Okay okay
              </Button>
            </Modal.Actions>
          </Modal>
        <Footer />
      </Container>
    </React.Fragment>
  );
};

export default GamesListPage;