import React, { FunctionComponent } from 'react';
import { Button, Divider, Grid, Header, Icon, Image, Label, Segment } from 'semantic-ui-react';
import { NFLTeams } from '../constants';
import { GameData } from '../containers/games/model';


interface GameProps{
    game: GameData,
    onUpvoteClicked(gameId: number): any
    onDownvoteClicked(gameId: number): any
};

const GameListItem: FunctionComponent<GameProps> = (props) => {

    const getTeamLogoByName = (teamname: string) : string|null => {
        let selectedTeam = NFLTeams.find(team => team.name === teamname);
    
        if(selectedTeam !== undefined){
          return selectedTeam.img;  
        }
        
        return null;
    };

    const _upvoteGame = (gameId: number): void => {
        // dispatch(upvoteGame(gameId));
        props.onUpvoteClicked(gameId);
    };

    const _downvoteGame = (gameId: number): void => {
        // dispatch(upvoteGame(gameId));
        props.onDownvoteClicked(gameId);
    };

    return (
        <Segment placeholder attached>
            <Grid columns={2} stackable textAlign='center'>
            <Divider vertical>
                <div className='voting_buttons'>
                    <Button onClick={() => _downvoteGame(props.game.id)}>
                        <Icon name="thumbs down outline" />
                    </Button>
                    <span>{props.game.votes}</span>
                    <Button onClick={() => _upvoteGame(props.game.id)}>
                        <Icon name="thumbs up outline" />
                    </Button>
                </div>

                <Label.Group tag className='tagGroup'>
                    {props.game.highscore_game === "1" && <Label as='a' className='tagColor'>Highscore Game</Label> }
                    {props.game.defense_battle === "1"  && <Label as='a' className='tagColor'>Defense Battle</Label> }
                    {props.game.thriller_game === "1"  && <Label as='a' className='tagColor'>Thriller Game</Label> }
                </Label.Group>

            </Divider>


            <Grid.Row verticalAlign='middle'>
                <Grid.Column>
                <Header icon>
                    { getTeamLogoByName(props.game.away) !== null && <Image src={require(`../images/` + getTeamLogoByName(props.game.away))} fluid className='teamLogo' /> }
                    <p>{ props.game.away }</p>
                </Header>

                </Grid.Column>

                <Grid.Column className='columnLast'>
                <Header icon>
                    { getTeamLogoByName(props.game.home) !== null && <Image src={require(`../images/` + getTeamLogoByName(props.game.home))} fluid className='teamLogo' /> }
                    <p>{ props.game.home }</p>
                </Header>
                </Grid.Column>
            </Grid.Row>
            </Grid>
        </Segment>
    )
};


export default GameListItem;
