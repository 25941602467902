export enum StoredKeys  {
	USER_DETAILS =  "USER_DETAILS"
}

export enum Language {
    ENGLISH = "en",
    RUSSIAN = "ru"
}

export enum ResponseType {
    FULFILLED = "FULFILLED",
    REJECTED = "REJECTED"
}

export const NFLTeams = [
	{ name: "Buffalo Bills", img: "nfl-buffalo-bills-team-logo.png", id: 1 },
	{ name: "New Orleans Saints", img: "nfl-new-orleans-saints-team-logo.png", id: 2 },
	{ name: "Indianapolis Colts", img: "nfl-indianapolis-colts-team-logo.png", id: 3 },
	{ name: "Dallas Cowboys", img: "nfl-dallas-cowboys-team-logo.png", id: 4 },
    { name: "Tampa Bay Buccaneers", img: "nfl-tampa-bay-buccaneers-team-logo.png", id: 5 },
    { name: "New England Patriots", img: "nfl-new-england-patriots-team-logo.png", id: 6 },
    { name: "Pittsburgh Steelers", img: "nfl-pittsburgh-steelers-team-logo.png", id: 7 },
    { name: "Atlanta Falcons", img: "nfl-atlanta-falcons-team-logo.png", id: 8 },
    { name: "Denver Broncos", img: "nfl-denver-broncos-team-logo.png", id: 9 },
    { name: "Baltimore Ravens", img: "nfl-baltimore-ravens-team-logo.png", id: 10 },
    { name: "Green Bay Packers", img: "nfl-green-bay-packers-team-logo.png", id: 11 },
    { name: "Chicago Bears", img: "nfl-chicago-bears-team-logo.png", id: 12 },
    { name: "Jacksonville Jaguars", img: "nfl-jacksonville-jaguars-team-logo.png", id: 13 },
    { name: "Detroit Lions", img: "nfl-detroit-lions-team-logo.png", id: 14 },
    { name: "Cleveland Browns", img: "nfl-cleveland-browns-team-logo.png", id: 15 },
    { name: "Houston Texans", img: "nfl-houston-texans-team-logo.png", id: 16 },
    { name: "New York Jets", img: "nfl-new-york-jets-team-logo.png", id: 17 },
    { name: "Minnesota Vikings", img: "nfl-minnesota-vikings-team-logo.png", id: 18 },
    { name: "Washington Commanders", img: "nfl-washington-football-team-logo.png", id: 19 },
    { name: "New York Giants", img: "nfl-new-york-giants-team-logo.png", id: 20 },
    { name: "Tennessee Titans", img: "nfl-tennessee-titans-team-logo.png", id: 21 },
    { name: "Philadelphia Eagles", img: "nfl-philadelphia-eagles-team-logo.png", id: 22 },
    { name: "Seattle Seahawks", img: "nfl-seattle-seahawks-team-logo.png", id: 23 },
    { name: "Los Angeles Rams", img: "nfl-los-angeles-rams-logo.png", id: 24 },
    { name: "Miami Dolphins", img: "nfl-miami-dolphins-logo018.png", id: 25 },
    { name: "San Francisco 49ers", img: "nfl-san-francisco-49ers-team-logo.png", id: 26 },
    { name: "Kansas City Chiefs", img: "nfl-kansas-city-chiefs-team-logo.png", id: 27 },
    { name: "Cincinnati Bengals", img: "nfl-cincinnati-bengals-team-logo.png", id: 28 },
    { name: "Los Angeles Chargers", img: "nfl-los-angeles-chargers-team-logo.png", id: 29 },
    { name: "Las Vegas Raiders", img: "nfl-las-vegas-raiders-team-logo.png", id: 30 },
    { name: "Carolina Panthers", img: "nfl-carolina-panthers-team-logo.png", id: 31 },
    { name: "Arizona Cardinals", img: "nfl-arizona-cardinals-team-logo.png", id: 32 },
];

export const GameDay2CalendarMapping = [
    { gameday: 13, startDate: "2022-12-02 00:00:00", endDate: "2022-12-08 23:59:59" },
    { gameday: 14, startDate: "2022-12-09 00:00:00", endDate: "2022-12-15 23:59:59" },
    { gameday: 15, startDate: "2022-12-16 02:30:00", endDate: "2022-12-23 02:15:00" },
    { gameday: 16, startDate: "2022-12-23 02:30:00", endDate: "2022-12-30 02:15:00" },
    { gameday: 17, startDate: "2022-12-30 02:30:00", endDate: "2023-01-08 02:15:00" },
    { gameday: 18, startDate: "2023-01-08 02:30:00", endDate: "2023-01-14 22:30:00" },
    { gameday: 19, startDate: "2023-01-14 22:45:00", endDate: "2023-01-20 02:15:00" },
    { gameday: 20, startDate: "2023-01-21 22:40:00", endDate: "2023-01-27 02:15:00" },
    { gameday: 21, startDate: "2023-01-29 21:00:00", endDate: "2023-02-11 02:15:00" },
    // { gameday: 22, startDate: "2023-01-21 22:40:00", endDate: "2023-01-27 02:15:00" }
]