import React from 'react';
import { Menu } from 'semantic-ui-react';

const SubNav = ({ activeItem, onGameDayClicked }) => {
  const handleOnClick = (_e: any, { gameday }: any) => onGameDayClicked(gameday);

  return (
    <React.Fragment>
       <div className="wrapper" style={{ overflowX: 'auto' }}>
      <Menu>
        <Menu.Item
          active={activeItem === 13}
          name="gameDay13"
          gameday="13"
          onClick={handleOnClick}
        >
          Week 13
        </Menu.Item>
        <Menu.Item
          active={activeItem === 14}
          name="gameDay14"
          gameday="14"
          onClick={handleOnClick}
        >
          Week 14
        </Menu.Item>
        <Menu.Item
          active={activeItem === 15}
          name="gameDay15"
          gameday="15"
          onClick={handleOnClick}
        >
          Week 15
        </Menu.Item>
        <Menu.Item
          active={activeItem === 16}
          name="gameDay16"
          gameday="16"
          onClick={handleOnClick}
        >
          Week 16
        </Menu.Item>
        <Menu.Item
          active={activeItem === 17}
          name="gameDay17"
          gameday="17"
          onClick={handleOnClick}
        >
          Week 17
        </Menu.Item>
        <Menu.Item
          active={activeItem === 18}
          name="gameDay18"
          gameday="18"
          onClick={handleOnClick}
        >
          Week 18
        </Menu.Item>
        <Menu.Item
          active={activeItem === 19}
          name="gameDay19"
          gameday="19"
          onClick={handleOnClick}
        >
          Wild Card
        </Menu.Item>
        <Menu.Item
          active={activeItem === 20}
          name="gameDay20"
          gameday="20"
          onClick={handleOnClick}
        >
          Divisional
        </Menu.Item>
        <Menu.Item
          active={activeItem === 21}
          name="gameDay21"
          gameday="21"
          onClick={handleOnClick}
        >
          Championship
        </Menu.Item>
      </Menu>
      </div>
    </React.Fragment>
  );
};

export default SubNav;
