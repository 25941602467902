
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import GamesListPage from '../containers/GamesListPage';
import ImprintPage from '../containers/ImprintPage';

import '../App.css';


function App() {
  
    let location = useLocation();

    const TRACKING_ID = "G-J909EN9BR5";
    ReactGA.initialize(TRACKING_ID);
    ReactGA.set({ anonymizeIp: true });

    useEffect(() => {
      ReactGA.send("pageview");
    }, [location]);

  return (
      <Routes>
        <Route path="/" element={<GamesListPage />} />
        <Route path="/imprint" element={<ImprintPage />} />
      </Routes>
  );
}

export default App;