import React from 'react';
import { Image, Segment } from 'semantic-ui-react';
import packageJson from '../../package.json';

const Footer = () => (

  <Segment basic className="footer">
    <a href="https://www.manuelwenner.de" rel="noopener">&copy; manuelwenner.de</a>
    🏈 <Image 
                src={require(`../images/twitter-logo.png`)} 
                as='a'
                size='mini'
                href='https://twitter.com/GamesVoter'
                target='_blank' />
    🏈 <a href="imprint" rel="noopener">Imprint</a>
    🏈 <span>v{packageJson.version}</span>
    
  </Segment>
);

export default Footer;
