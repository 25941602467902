import { GamesReducer } from "../model";
import Events from "./events";
import { ResponseType } from "../../../constants";
import { initGamesData } from '../init'

const initialState: GamesReducer = {
	fetchGamesDataCompleted: null,
	gamesData: initGamesData()
};

var reducer = (state: GamesReducer = initialState, action:any) : GamesReducer => {
	switch (action.type) {
		case Events.FETCH_GAMES_FULFILLED:
			return {
				...state,
				fetchGamesDataCompleted: ResponseType.FULFILLED,
				gamesData: action.payload.data.data
			};
		case Events.FETCH_GAMES_BY_WEEK_FULFILLED:
			return {
				...state,
				fetchGamesDataCompleted: ResponseType.FULFILLED,
				gamesData: action.payload.data.data
			};
		case Events.UPVOTE_GAME_FULFILLED:
			return {
				...state,
				fetchGamesDataCompleted: ResponseType.FULFILLED,
				gamesData: action.payload.data.data
			};
		default:
			return state;
	}
};

export default reducer;